const firebaseConfig = {
  apiKey: "AIzaSyBw5Gy13G1W_TdeIdl9ZPoJSltD3LPGXZQ",
  authDomain: "nwr-prod-env.firebaseapp.com",
  projectId: "nwr-prod-env",
  storageBucket: "nwr-prod-env.appspot.com",
  messagingSenderId: "522967256994",
  appId: "1:522967256994:web:5ea89c2ff5528b958cacfb",
  measurementId: "G-EQX0K01VV2"
};

export default firebaseConfig;

